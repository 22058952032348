import React, { Component } from 'react';
import  Layout  from './components/Layout';
import 'bootstrap/dist/css/bootstrap.min.css';
import CacheProvider from './Helpers/CacheHelper';


export default class App extends Component {
  static displayName = App.name;
  render() {
    return (
      <CacheProvider>
        <Layout/>
      </CacheProvider>
    );
  }
}
