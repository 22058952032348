import React, { useState, useRef } from 'react';
import * as Icons from 'react-bootstrap-icons';
import './TreeViewNode.css';

function ActionsPanel({ onAction, init, showActions, nodeId, setShowActions, displayAdd, displayEdit, displayDelete }) {

    const handleAction = async (action) => {
        setShowActions(!showActions);
        onAction(action, nodeId);
    };

    const getStyle = () => {
        if (init) {
            let buttonRect = init.getBoundingClientRect();
            let stylesBtn = getComputedStyle(init, null);
            let btnLeft = stylesBtn.width === "auto" ? -20 : parseInt(stylesBtn.width);
            let style = {
                top: '-5px',
                left: (buttonRect.left - btnLeft - 20) + 'px'
            };
            return style;
        } else {
            return {};
        }
    }

    return (
        <>
            {showActions && (
                <div className="action-panel" style={getStyle()} onMouseLeave={() => setShowActions(false)}>
                    {displayEdit && (<i className='ic icon-edit-panel' onClick={() => handleAction(`edit`)} ></i>)}
                    {displayDelete && (<i className='ic icon-delete-panel' onClick={() => handleAction(`delete`)}  ></i>)}
                    {displayAdd && (<i className='ic icon-add-panel' onClick={() => handleAction(`add`)} ></i>)}
                </div>
            )}
        </>
    );
}

export default ActionsPanel;
