import * as THREE from 'three';

export default class GeometryHelper {
    constructor() {
    }

    moveAlongVector(
        point = new THREE.Vector3(0, 0, 0),
        direction = new THREE.Vector3(0, 0, 0),
        distance = 1) {

        return new THREE.Vector3()
            .addVectors(point, direction.multiplyScalar(distance))
    }
}
