import React, { useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { Modal, ModalHeader, ModalBody, Progress, Input, Button, Label, ModalFooter } from 'reactstrap';
import axios from 'axios';

function FileUpload({ onChangeOpenState, info, authToken }) {
    const defaultColor = "secondary";
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");
    const [comments, setComments] = useState("n/a");
    const [uploadProgress, setUploadProgress] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(true);
    const [inputFileNameColor, setInputFileNameColor] = useState(defaultColor)

    const baseApiUrl = process.env.REACT_APP_API_URL;
    const openModal = () => setModalIsOpen(true);
    const closeModal = () => {
        onChangeOpenState(false);
        setModalIsOpen(false)
    };


    const changeFileName = (e) => {
        setFileName(e.target.value)
        if (inputFileNameColor != defaultColor) {
            setInputFileNameColor(defaultColor);

        }

        console.log(e.target.value);
    }

    const changeComments = (e) => {

        setComments(e.target.value);
    };

    const onDrop = async (acceptedFiles) => {
        const uploadedFile = acceptedFiles[0];
        setFile(uploadedFile);
        setFileName(uploadedFile.name.replace(".ply", ""));
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });


    const sendToServer = async () => {
        try {
            const formData = new FormData();
            formData.append('File', file);
            formData.append('FolderId', info.id);
            formData.append('FileName', fileName);
            formData.append('Comments', comments);
            const response = await axios.post(`${baseApiUrl}/FileManager/create`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${authToken}`
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setUploadProgress(progress);
                }
            });
            console.log('File uploaded successfully:', response.data);
        } catch (error) {
            console.error('Error uploading file:', error);
        } finally {
            closeModal();
        }
    }

    const sendToServerhandler = async () => {
        if (fileName.length > 0) {
            await sendToServer()
        }
        else {
            setInputFileNameColor("danger");
        }
    }

    return (
        <Modal isOpen={modalIsOpen} toggle={closeModal}>
            <ModalHeader className={"custom-modal-text"} toggle={closeModal}>Loading file...</ModalHeader>
            <ModalBody>
                <div {...getRootProps()} style={dropzoneStyles}>
                    <input {...getInputProps()} />
                    <p>Drag file here or click to select file</p>
                    {file && <p>Selected file: {file.name}</p>}
                </div>
                <p></p>
                <Label>Comments</Label>
                <Input onChange={changeComments} value={comments} />
                
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',height:"85px" }}>
                    <span style={{ marginBottom: '25px', marginTop:"25px" }} className='progres-text'>Progress:{' '}{uploadProgress}%</span>
                    <Progress style={{height:"5px",width:"450px"}}  value={uploadProgress} max="100" />
                </div>
            </ModalBody>

            <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div></div>
                <button className='custom-btn btn-blue' onClick={() => sendToServerhandler()}>
                <i className='ic icon-chekced'></i>{' '}
                  <span>Upload</span>
                </button>
            </ModalFooter>
        </Modal>
    );
}

const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
};

export default FileUpload;