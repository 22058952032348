import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { Canvas, useThree } from 'react-three-fiber';
import McCable from './McCable';
import Receptacle from './Receptacle';
import HomerunBox from './HomerunBox';
import Panel from './Panel';
import { CreateConduits } from './Conduit';
import { useCacheContext } from '../../../Helpers/CacheHelper';
import useAuthLoader from '../../../hooks/useAuthLoader';
import JsonModel from '../ElementsV2/JsonModel';
import { useMemo } from 'react';


const CreateModelFromJson = ({ auth, guid, setLoadProgress, setProgressViewState, group}) => {
  const { scene } = useThree();
  const scale = 0.377953 / 10;
  const offsetX = -100;
  const offsetZ = -100;

  const baseApiUrl = process.env.REACT_APP_API_URL;
  const token = auth.token;
  const { addToCache, getFromCache } = useCacheContext();
  const loadConf = {
    isPly: false,
    url: `${baseApiUrl}/api/model/ply/${guid}`,
    guid,
    token,
    setLoadProgress,
    setProgressViewState,
    cache: { addToCache, getFromCache }
  };
  const { data, loading, error } = useAuthLoader(loadConf);



  const createPanels = (items) => {
    const panels = [];
    try {
      items = items.filter(item => item.elementType === "CONDUIT");

      const grouped = items.reduce((result, item) => {
        (result[item.data.panelName] = result[item.data.panelName] || []).push(item);
        return result;
      }, {});

      for (const [groupName, runs] of Object.entries(grouped)) {
        panels.push(<Panel key={groupName} runs={runs} offsetX={offsetX} offsetY={offsetZ} scale={scale} />);
      }
    } catch (error) {
      console.log(error);
    }
    return panels;
  };


  // useEffect(() => {
  //   return () => {
  //     try {
  //       createPanels(data["1"])
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  // }, [data, scale, offsetX, offsetZ, scene]);

  useMemo(() => {
    if (data) {
      new JsonModel(scene,group).create(data["1"]);
    }else{
      console.log(data);
    }
  }, [data])

  if (loading) return null;
  if (error) {
    console.log(error);
    return (
      <mesh visible userData={{ hello: 'world' }} position={[0, 0, 0]} rotation={[Math.PI / 2, 0, 0]}>
        <sphereGeometry args={[1, 16, 16]} />
        <meshStandardMaterial color="hotpink" transparent />
      </mesh>
    );
  }


  // return (
  //   <>
  //     {data&&Array.isArray(data["1"]) && (
  //       <>{<CreateConduits items={data["1"]} scale={scale} offsetX={offsetX} offsetZ={offsetZ} />}
  //         {Array.isArray(data["1"]) && data["1"].map((element) => {
  //           return (
  //             <React.Fragment key={element.id}>
  //               {element.elementType === 'MC_CABLE' && <HomerunBox key={element.id} item={element} scale={scale} offsetX={offsetX} offsetY={offsetZ} scene={scene} />}
  //               {element.elementType === 'MC_CABLE' && <McCable key={element.id} startPoint={element.pathPoints[0]} endPoint={element.pathPoints[1]} scale={scale} offsetX={offsetX} offsetY={offsetZ} />}
  //               {element.elementType === 'MC_CABLE' && <Receptacle key={element.id} item={element} scale={scale} offsetX={offsetX} offsetY={offsetZ} />}
  //             </React.Fragment>
  //           );
  //         })}</>
  //     )}

  //   </>
  // );
  return null;
};

export default CreateModelFromJson;
