import React, { useState, useRef } from 'react';
import * as Icons from 'react-bootstrap-icons';
import ActionsPanel from './ActionsPanel';
import './TreeViewNode.css';

function TreeViewFileNode({ node, onFileSelected, onFileAction, index, selectedIndexes }) {
    const [showActions, setShowActions] = useState(false);
    const checkBox = useRef(0);
    const [init, setInit] = useState(null);

    const handleActionsClick = (e) => {
        e.stopPropagation();
        setInit(e.target);
        setShowActions(!showActions);
    };
    return (
        <div>

            <div className='tree-node'>
                <input
                    checked={selectedIndexes.some(item => item.index === index && item.fileId === node.Id)}
                    onClick={() => onFileSelected(node, index)}
                    type='checkbox'></input>
                <span title={node.Name} className='node-text'>
                    {node.Name}
                </span>
                <span className='three-dots' onClick={handleActionsClick} >
                    {showActions ? (<ActionsPanel
                        key={node.Id}
                        init={init}
                        onAction={onFileAction}
                        nodeId={node.Id}
                        showActions={showActions}
                        setShowActions={setShowActions}
                        displayAdd={false}
                        displayDelete={true}
                        displayEdit={true}
                    />) : (<Icons.ThreeDots size={16} color="gray" />)}
                </span>
            </div>
        </div>
    );
}

export default TreeViewFileNode;
