import React, { useRef, useState, Suspense, useEffect } from 'react';
import { useThree } from 'react-three-fiber';
import * as THREE from 'three';
import { useCacheContext } from '../../Helpers/CacheHelper';
import useAuthLoader from '../../hooks/useAuthLoader';


const PLYModel = ({ auth, guid, setOrbitPoint, setLoadProgress, setProgressViewState }) => {

  const [meshOpacity, setMeshOpacity] = useState(1.0);
  const baseApiUrl = process.env.REACT_APP_API_URL;
  const token = auth.token;
  const { addToCache, getFromCache } = useCacheContext();
  const loadConf = {
    isPly: true,
    url: `${baseApiUrl}/api/model/ply/${guid}`,
    guid,
    token,
    setLoadProgress,
    setProgressViewState,
    cache: { addToCache, getFromCache }
  };

  const { data, loading, error } = useAuthLoader(loadConf);

  const meshRef = useRef();
  const { camera, gl } = useThree();

 

  const handleObjectClick = (event) => {
    const { object } = event.intersections[0];
    const { offsetX, offsetY } = event.nativeEvent;
    const mouseVector = new THREE.Vector2();
    mouseVector.x = (offsetX / gl.domElement.clientWidth) * 2 - 1;
    mouseVector.y = -(offsetY / gl.domElement.clientHeight) * 2 + 1;
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouseVector, camera);
    const intersects = raycaster.intersectObject(object);
    if (intersects.length > 0) {
      const { point } = intersects[0];
      setOrbitPoint(point);
    }
  };

  if (loading) return null;
  if (error) {
    console.log(error);
    return (
      <mesh visible userData={{ hello: 'world' }} position={[1, 2, 3]} rotation={[Math.PI / 2, 0, 0]}>
        <sphereGeometry args={[1, 16, 16]} />
        <meshStandardMaterial color="hotpink" transparent />
      </mesh>
    );
  }

  return (<>
    {data && Array.isArray(data["1"]) ? (null) :
      (<>{data && (<mesh
        userData={{ ply: true }}
        onPointerOver={() => { setMeshOpacity(0.1) }}
        onPointerOut={() => { setMeshOpacity(1) }}
        key={guid} onClick={handleObjectClick}
        ref={meshRef}
        geometry={data}
        rotation={[(Math.PI / 2) * -1, 0, 0]}>
        <meshNormalMaterial transparent opacity={meshOpacity} />
      </mesh>)}</>)}
  </>

  );
};

export default PLYModel;