import React, { useContext, useState, useEffect } from "react";
import useAuth from "./useAuth";

const AuthContext = React.createContext();

export const useAuthContext=()=>{
 return useContext(AuthContext)
}

const AuthProvider = ({ children }) => {
    const auth = useAuth();
    const [keycloak, setKeycloak] = useState(null);

    useEffect(() => {
        if (auth.kc) {
            setKeycloak(auth.kc);
        }
    }, [auth]);

    return (
        <AuthContext.Provider value={{keycloak}}>
            {children}
        </AuthContext.Provider>
    );
};
export default AuthProvider;
