import * as THREE from 'three';
import { DragControls } from 'three/examples/jsm/controls/DragControls.js';
import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js';
import { TransformControls, TransformControlsPlane } from 'three/examples/jsm/controls/TransformControls';
import PlaneHelper from './PlaneHelper';

export default class CrossSectionalArea {

    constructor(group = new THREE.Group(), camera, scene, renderer, onChangeOrbit) {
        this.camera = camera;
        this.scene = scene;
        this.renderer = renderer;
        this.clipPlane = undefined;
        this.planeHelper = undefined;
        this.onChangeOrbit = onChangeOrbit;
        this.innerCap = undefined;
        this.outerCap = undefined;
        this.group = group ?? new THREE.Group();
        this.clipGroup = new THREE.Group();
        this.boxMeshesGroup = new THREE.Group();
       
        
        this.transformControls = undefined;
        this.isActiveChangeOrbit = true;
        this.params = {
            plane: 0,
            planeConstant: 0,
        }

        this.planes = [
            new THREE.Plane(new THREE.Vector3(-1, 0, 0), 0),
            new THREE.Plane(new THREE.Vector3(0, -1, 0), 0),
            new THREE.Plane(new THREE.Vector3(0, 0, -1), 0)
        ];
    }




    undo() {
        this.transformControls.remove();
        this.transformControls.dispose();
        this.scene.remove(this.transformControls);
        this.scene.remove(this.planeHelper);
        this.scene.remove(this.clipGroup);
        this.clipGroup.clear();
        this.boxMeshesGroup.clear();
        this.scene.add(this.group);
        this.renderer.localClippingEnabled = false;
    }

    
    createPlaneStencilGroup(geometry, group, plane, renderOrder) {
        const baseMat = new THREE.MeshBasicMaterial();
        baseMat.depthWrite = false;
        baseMat.depthTest = false;
        baseMat.colorWrite = false;
        baseMat.stencilWrite = true;
        baseMat.stencilFunc = THREE.AlwaysStencilFunc;

       
        // back faces
        const mat0 = baseMat.clone();
        mat0.side = THREE.BackSide;
        mat0.clippingPlanes = [plane];
        mat0.stencilFail = THREE.IncrementWrapStencilOp;
        mat0.stencilZFail = THREE.IncrementWrapStencilOp;
        mat0.stencilZPass = THREE.IncrementWrapStencilOp;

        const mesh0 = new THREE.Mesh(geometry, mat0);
        mesh0.renderOrder = renderOrder;
        group.add(mesh0);

        // front faces
        const mat1 = baseMat.clone();
        mat1.side = THREE.FrontSide;
        mat1.clippingPlanes = [plane];
        mat1.stencilFail = THREE.DecrementWrapStencilOp;
        mat1.stencilZFail = THREE.DecrementWrapStencilOp;
        mat1.stencilZPass = THREE.DecrementWrapStencilOp;

        const mesh1 = new THREE.Mesh(geometry, mat1);
        mesh1.renderOrder = renderOrder;

        group.add(mesh1);

        return group;

    }

    /*
    createCap method
    returns coloured plane that fills in the stencil
    Params: - colour, the Hex code colour
            - renderOrder, the Int render order
    */
    createCap(colour, renderOrder,group) {

        const capMat = new THREE.MeshStandardMaterial({
            color: colour,
            metalness: 0.1,
            roughness: 0.75,
            stencilWrite: true,
            stencilRef: 0,
            stencilFunc: THREE.NotEqualStencilFunc,
            stencilFail: THREE.ReplaceStencilOp,
            stencilZFail: THREE.ReplaceStencilOp,
            stencilZPass: THREE.ReplaceStencilOp

        });
        const cap = new THREE.Mesh(new THREE.PlaneGeometry(4, 4), capMat);
        // clear the stencil buffer


        cap.onAfterRender = function (renderer) {

            renderer.clearStencil();

        };
        const boundingBox = new THREE.Box3().setFromObject(group);
        const center = new THREE.Vector3();
        boundingBox.getCenter(center);
        cap.position.set(center.x,center.y,center.z);
        cap.renderOrder = renderOrder;
        return cap;
    }

    getSize(group) {
        const boundingBox = new THREE.Box3().setFromObject(group);
        const size = new THREE.Vector3();
        return ((boundingBox.getSize(size).x + boundingBox.getSize(size).y))*Math.PI;
    }

    setPlanePosition(group) {
        const boundingBox = new THREE.Box3().setFromObject(group);
        const center = new THREE.Vector3();
        boundingBox.getCenter(center);
        if (this.params.plane === 0) {
            this.planeHelper.position.y = 0;
            this.planeHelper.position.z = 0;
            this.clipPlane.constant = center.x;
        } else if (this.params.plane === 1) {
            this.planeHelper.position.x = 0;
            this.planeHelper.position.z = 0;
            this.clipPlane.constant = center.y;
        } else {
            this.planeHelper.position.y = 0;
            this.planeHelper.position.x = 0;
            this.clipPlane.constant = center.z;
        }
    }
    
    //INIT
    init() {


        // Lights

        // const dirLight = new THREE.DirectionalLight(0xffffff, 1);
        // dirLight.position.set(5, 10, 7.5);
        // dirLight.castShadow = true;
        // dirLight.shadow.camera.right = 2;
        // dirLight.shadow.camera.left = -2;
        // dirLight.shadow.camera.top = 2;
        // dirLight.shadow.camera.bottom = -2;
        // dirLight.shadow.mapSize.width = 1024;
        // dirLight.shadow.mapSize.height = 1024;
        // this.scene.add(dirLight);

        //Clipping plane
        this.clipPlane = this.planes[this.params.plane];


        //Renderer

        this.renderer.shadowMap.enabled = true;
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        //this.renderer.setClearColor(0x263238);


        this.renderer.localClippingEnabled = true;

        //PlaneHelper
        const panelColor = 0x263238;
        const size= this.getSize(this.group);
        this.planeHelper = new PlaneHelper(this.clipPlane, size, panelColor)
        this.setPlanePosition(this.group);
        this.scene.add(this.planeHelper);

        this.transformControls = new TransformControls(this.camera, this.renderer.domElement);
        this.transformControls.attach(this.planeHelper);
        this.transformControls.mode = "translate";

        if (this.params.plane === 0) {
            this.transformControls.showX = true;
            this.transformControls.showY = false;
            this.transformControls.showZ = false;
        } else if (this.params.plane === 1) {
            this.transformControls.showX = false;
            this.transformControls.showY = true;
            this.transformControls.showZ = false;
        } else {
            this.transformControls.showX = false;
            this.transformControls.showY = false;
            this.transformControls.showZ = true;
        }
        this.scene.add(this.transformControls);

        //Inner Cube		
        const boxMatInner = (color) => new THREE.MeshStandardMaterial({
            color: color,
            metalness: 0.1,
            roughness: 0.75,
            side: THREE.DoubleSide,
            clippingPlanes: [this.clipPlane],
            clipShadows: true,
            shadowSide: THREE.DoubleSide,
        });

        const meshNormalMaterial = new THREE.MeshNormalMaterial({
            roughness: 0.75,
            side: THREE.DoubleSide,
            clippingPlanes: [this.clipPlane],
            clipShadows: true,
            shadowSide: THREE.DoubleSide,
        });



        // Inner object stencil
        this.scene.remove(this.group);
        this.group.children.forEach(e => {
            if (e.userData?.ply) {
                const boxMeshInner = new THREE.Mesh(e.geometry, meshNormalMaterial);
                boxMeshInner.rotation.set((Math.PI / 2) * -1, 0, 0);
                boxMeshInner.castShadow = true;
                this.createPlaneStencilGroup(e.geometry, this.clipGroup, this.clipPlane, 3);
                boxMeshInner.position.set(e.position.x, e.position.y, e.position.z);
                this.boxMeshesGroup.add(boxMeshInner);

            } else {
                const boxMeshInner = new THREE.Mesh(e.geometry, boxMatInner(e.material.color.getHex()));
                boxMeshInner.castShadow = true;
                this.createPlaneStencilGroup(e.geometry, this.clipGroup, this.clipPlane, 3);
                boxMeshInner.position.set(e.position.x, e.position.y, e.position.z);
                this.boxMeshesGroup.add(boxMeshInner);
            }
        });

        this.scene.add(this.clipGroup);
        this.scene.add(this.boxMeshesGroup);
        this.innerCap = this.createCap(0xfff000, 3.1,this.group);
        this.scene.add(this.innerCap);



        this.transformControls.addEventListener('mouseDown', () => {
            this.onChangeOrbit(false);
        });

        this.transformControls.addEventListener('mouseUp', () => {
            this.onChangeOrbit(true);
        });

        this.transformControls.addEventListener('objectChange', (event) => {
            if (this.params.plane === 0) {
                this.transformControls.object.position.y = 0;
                this.transformControls.object.position.z = 0;
                this.clipPlane.constant = this.transformControls.object.position.x;
            } else if (this.params.plane === 1) {
                this.transformControls.object.position.x = 0;
                this.transformControls.object.position.z = 0;
                this.clipPlane.constant = this.transformControls.object.position.y;
            } else {
                this.transformControls.object.position.y = 0;
                this.transformControls.object.position.x = 0;
                this.clipPlane.constant = this.transformControls.object.position.z;
            }
        });


    

        // this.gui = new GUI();
        // this.gui.add(this.params, 'plane', {
        //     'X': 0,
        //     'Y': 1,
        //     'Z': 2
        // }).onChange((d) => {
        //     //Set clipping plane

        //     this.clipPlane = this.planes[d];

        //     //Refresh planeHelper
        //     this.planeHelper.plane = this.clipPlane;

        //     this.dragControls.addEventListener('drag', (event) => {

        //         if (this.params.plane === 0) {
        //             event.object.position.y = 0;
        //             event.object.position.z = 0;
        //             this.clipPlane.constant = event.object.position.x;
        //         } else if (this.params.plane === 1) {
        //             event.object.position.x = 0;
        //             event.object.position.z = 0;
        //             this.clipPlane.constant = event.object.position.y;
        //         } else {
        //             event.object.position.y = 0;
        //             event.object.position.x = 0;
        //             this.clipPlane.constant = event.object.position.z;
        //         }
        //     });
        // });
        // this.gui.add(this.params, 'planeConstant').min(-20).max(20).onChange((d) => {
        //     this.clipPlane.constant = d;
        // });

    }
}