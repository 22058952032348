import React, { useEffect } from "react";
import * as THREE from 'three';
import { useThree } from 'react-three-fiber';
import { useThreeContext } from "./Context/ThreeContext";
import CrossSectionalArea from "./CrossSectionalArea";


function CrossSectional({ isShow, groups, toggleOrbitControls }) {

    const { pick, setPickPane } = useThreeContext();
    const { gl, scene, camera } = useThree();

    useEffect(() => {
        const initializePick = () => {
            if (isShow) {
                if (pick) {
                    pick.init();
                } else {
                    const tempGroup = new THREE.Group();
                    groups.filter(x => x != undefined).forEach(x => x.children.forEach(o => tempGroup.add(o)));
                    if (tempGroup.children.length < 1)
                        return;
                    
                    let pickPlane = new CrossSectionalArea(tempGroup, camera, scene, gl, toggleOrbitControls);
                    setPickPane(pickPlane);
                    pickPlane.init();
                }
            } else {
                if (pick) {
                    pick.undo();
                }
            }
        };

        initializePick();
    }, [isShow]);

    return null;
};

export default CrossSectional;