import React, { useState, useEffect, useRef } from 'react';
import * as Icons from 'react-bootstrap-icons';
import TreeViewFileNode from './TreeViewFileNode';
import ActionsPanel from './ActionsPanel';
import './TreeViewNode.css'

function TreeViewNode({ onChangeFolderId, node, onFolderClick, onFileDataChange, onFileAction, onFolderAction, onSelectedFileNode, selectedIndexes }) {
  const [isOpen, setIsOpen] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [init, setInit] = useState(null);
  const handleFileDataChange = (child) => {
    let data = (child.Files.FileViewModel && Array.isArray(child.Files.FileViewModel)) ?
      child.Files.FileViewModel : child.Files.FileViewModel && [child.Files.FileViewModel];
    onFileDataChange(data);
  };

  const handleChangeFolderId = (id, name) => {
    if (!isOpen) {
      onChangeFolderId({ id, name });
    }
    else {
      onChangeFolderId(null);
    }
  }

  const handleFolderClick = (folderId, node) => {
    handleChangeFolderId(folderId, node.Name);
    setIsOpen(!isOpen);
    setSelectedFolderId(folderId);
    setShowActions(false);
    handleFileDataChange(node);

  };

  const handleActionsClick = (e) => {
    e.stopPropagation();
    setInit(e.target);
    setShowActions(!showActions);
  };

  const renderThreeNode = (item) => {
    return (<TreeViewNode
      key={item.Id}
      onChangeFolderId={onChangeFolderId}
      node={item}
      onFolderClick={onFolderClick}
      onFileDataChange={onFileDataChange}
      onFileAction={onFileAction}
      onFolderAction={onFolderAction}
      onSelectedFileNode={onSelectedFileNode}
      selectedIndexes={selectedIndexes} />
    )
  }


  return (
    <>
      <div>
        <div className={isOpen && selectedFolderId === node.Id ? 'tree-node active-node' : 'tree-node'}>
          <span className='node-text' onClick={() => handleFolderClick(node.Id, node)}>
            {isOpen ? <Icons.ChevronDown size={12} className="bg-light" /> : <Icons.ChevronRight size={12} className="bg-light" />}
            {node.Name}
          </span>

          <span className='three-dots' onClick={handleActionsClick} >
            {showActions ? (<ActionsPanel
              key={node.Id}
              init={init}
              onAction={onFolderAction}
              nodeId={node.Id}
              showActions={showActions}
              setShowActions={setShowActions}
              displayAdd={true}
              displayDelete={true}
              displayEdit={true}
            />) : (<Icons.ThreeDots size={16} color="gray" />)}
          </span>

        </div>
        {isOpen && (
          <ul>
            {(node.Subfolders.FolderViewModel && Array.isArray(node.Subfolders.FolderViewModel) ? (<>
              {node.Subfolders.FolderViewModel.map(child => (
                <li className='list-item-parent' key={child.Id}>
                  {renderThreeNode(child)}
                </li>
              ))}
            </>) : (node.Subfolders && node.Subfolders.FolderViewModel && (
              <li className='list-item-parent' key={node.Subfolders.FolderViewModel.Id}>
                {renderThreeNode(node.Subfolders.FolderViewModel)}
              </li>
            )))}

          </ul>
        )}

        {isOpen && node.Files.FileViewModel && (
          <ul>
            {node.Files.FileViewModel && Array.isArray(node.Files.FileViewModel) ?
              (node.Files.FileViewModel.map((el, index) => (
                <li className='list-item-parent' key={el.Id}>
                  <TreeViewFileNode
                    key={el.Id}
                    node={el}
                    onFileSelected={onSelectedFileNode}
                    onFileAction={onFileAction}
                    index={index}
                    selectedIndexes={selectedIndexes} />
                </li>
              ))) : node.Files.FileViewModel && (
                <li className='list-item-parent' key={node.Files.FileViewModel.Id}>
                  <TreeViewFileNode
                    onFileAction={onFileAction}
                    key={node.Files.FileViewModel.Id}
                    node={node.Files.FileViewModel}
                    onFileSelected={onSelectedFileNode}
                    selectedIndexes={selectedIndexes}
                    index={0} />
                </li>
              )}
          </ul>
        )}
      </div>
    </>
  );
}

export default TreeViewNode;
