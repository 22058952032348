import React, { createContext, useContext, useState } from 'react';

const ThreeActionsContext = createContext();

export const ThreeActionsProvider = ({ children }) => {
  const [actions, setActions] = useState({});
  const addAction = (actionName, actionFunction) => {
    setActions(prevActions => ({
      ...prevActions,
      [actionName]: actionFunction,
    }));
  };

  return (
    <ThreeActionsContext.Provider value={{ actions, addAction }}>
      {children}
    </ThreeActionsContext.Provider>
  );
};

export const useThreeActions = () => {
  const context = useContext(ThreeActionsContext); 
  if (!context) {
    throw new Error('useThreeActions must be used within a ThreeActionsProvider');
  }
  return context;
};
