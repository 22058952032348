import React, { useState, useEffect } from 'react';
import TreeViewNode from './TreeViewNode';
import { Input, Spinner, Row, Col, Button, Table, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DataHelper from '../../Helpers/DataHelper';
import axios from 'axios';

const Manager = ({ auth, onFileDataChange, onChangeFolderId, onRefreshKey,onSelectedFileNode,selectedIndexes}) => {
  let defaultActionData = {
    action: "",
    path: "",
    nodeId: ""
  };
  const baseApiUrl = process.env.REACT_APP_API_URL;
  const [data, setData] = useState(null);
  const [newFolderName, setNewFolderName] = useState('');
  const [actionData, setActionData] = useState(defaultActionData);
  const [isRenaming, setIsRenaming] = useState(false);
  const [newName, setNewName] = useState('');

  useEffect(() => {
    fetchData();
  }, [auth]);

 
  const handleFolderClick = async (folderId, action) => {
    if (action === 'delete') {
      // delete folder
      try {
        const response = await fetch(`/FileManager/delete/folder/${folderId}`);
        if (!response.ok) {
          throw new Error('Failed to delete folder');
        }
        // reload folder
        fetchData();
      } catch (error) {
        console.error('Error deleting folder:', error);
      }
    } else if (action === 'add') {
      // add folder
      try {
        const response = await fetch('/FileManager/add/folder', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name: newFolderName }), // edit folder name
        });
        if (!response.ok) {
          throw new Error('Failed to add folder');
        }
        // reload
        fetchData();
        //clear
        setNewFolderName('');
      } catch (error) {
        console.error('Error adding folder:', error);
      }
    }
  };


  //#region Moadal change
const handleRenameConfirm = async () => {

    if (actionData.action === "edit") {
      await renameActionSend();
    }
    else {
      await createFolder();
    }
    setIsRenaming(false);
    setActionData(defaultActionData);
    setNewName("");
  };

  const handleRenameCancel = () => {
    setNewName("");
    setIsRenaming(false);
    setActionData(defaultActionData);
  };

  const handleChange = (e) => {
    setNewName(e.target.value);
  };

  //#endregion
  
//#region Handle actions
const handleFileAction = async (action, nodeId) => {
  if (action === "delete") {
    await deleteAction("FileManager/delete", nodeId);
  } else
    if (action === "edit") {
      setIsRenaming(true);
      setActionData({ action: "edit", path: "FileManager/rename", nodeId: nodeId });

    }
};

const handleFolderAction = async (action, nodeId) => {
  if (action === "delete") {
    await deleteAction("api/Folders/delete", nodeId);
  } else
    if (action === "edit") {
      setIsRenaming(true);
      setActionData({ action: "edit", path: "api/Folders/rename", nodeId });
    } else if (action === "add") {
      setActionData({ action: "add", path: "api/Folders/create/child", nodeId })
      setIsRenaming(true);
    }
};

//#endregion
  

  if (!data) {
    return (
      <div style={{ paddingLeft: "5rem" }}>

        <span>
          {' '}Loading...{' '}
          <Spinner size="sm" color="secondary" />
        </span>

      </div>
    );;
  }

  const rootChildren = DataHelper.ParseXml(data);

  //#region Send to server
  const renameActionSend = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('name', newName);
    const config = {
      headers: {
        'Authorization': `Bearer ${auth?.token}`
      }
    };
    const response = await axios.patchForm(`${baseApiUrl}/${actionData.path}/${actionData.nodeId}`, formDataToSend, config);
    if (response) {
      onRefreshKey();
    } else {
      alert(response);
    }
  }

  const deleteAction = async (path, id) => {
    const response = await axios.delete(`${baseApiUrl}/${path}?ids=${id}`, {
      headers: {
        'Authorization': `Bearer ${auth?.token}`
      }
    });
    if (response) {
      onRefreshKey();
    } else {
      alert(response);
    }
  }


  const createFolder = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('name', newName);
    formDataToSend.append('parentId', actionData.nodeId);
    const config = {
      headers: {
        'Authorization': `Bearer ${auth?.token}`
      }
    };
    const response = await axios.post(`${baseApiUrl}/${actionData.path}`, formDataToSend, config);
    if (response) {
      onRefreshKey();
    } else {
      alert(response);
    }
  }

  async function fetchData() {
    try {
      if (!auth || !auth.token) {
        return;
      }
      const response = await fetch(`${baseApiUrl}/api/folders/get/root/byOwner/xml`, {
        headers: {
          Authorization: `Bearer ${auth.token}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.text();
      setData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  //#endregion

  function getActionName(action)
  {
      if(action=="edit")
      {
        return "Rename"
      }else{
        return "Add"
      }
  }


  return (
    <div className='manager-conteiner'>
      <span className='file-manager-conteiner'>File Manager</span>
      <hr className='hr-cs-line'/>
      {rootChildren.map(parent => (
        <TreeViewNode
          onFolderAction={handleFolderAction}
          onFileAction={handleFileAction}
          key={parent.Id}
          onChangeFolderId={onChangeFolderId}
          node={parent}
          onFolderClick={handleFolderClick}
          onFileDataChange={onFileDataChange}
          onSelectedFileNode={onSelectedFileNode}
          selectedIndexes={selectedIndexes} />
      ))}

      <Modal  isOpen={isRenaming} toggle={handleRenameCancel}>
        <ModalHeader className={"custom-modal-text"} color='#2E303F' toggle={handleRenameCancel}>{getActionName(actionData.action)}</ModalHeader>
        <ModalBody>
          <Input type='text' onChange={handleChange} value={newName} />
        </ModalBody>
        <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button className='custom-btn btn-white' onClick={handleRenameCancel}>
                  <i className='ic icon-close-default'></i>{' '}
                  <span>Cancel</span>
                </button>
                <button className='custom-btn btn-blue' onClick={handleRenameConfirm}>
                  <i className='ic icon-chekced'></i>{' '}
                  <span>Confirm</span>
                </button>
              </ModalFooter>
      </Modal>
    </div>
  );
}

export default Manager;
