import * as THREE from 'three';
import GeometryHelper from './GeometryHelper';
import ColorHelper from '../../../Helpers/ColorHelper';

export default class McCable {
    constructor(scene = new THREE.Scene()) {
        this.scene = scene
        this.startPoint = null
        this.endPoint = null
        this.direction = null
        this.representation = null
        this.fromBbox = false
    }

    create(mcCable, diameter, scale = 1, offsetX = 0, offsetY = 0, group) {
        this.fromBbox = mcCable.data.from_hrbox == "True"
        var startHeight = 1;
        if (this.fromBbox) { startHeight = 8; }

        this.startPoint = new THREE.Vector3(
            mcCable.pathPoints[0][0] * scale + offsetX,
            startHeight,
            mcCable.pathPoints[0][1] * scale + offsetY)


        this.endPoint = new THREE.Vector3(
            mcCable.pathPoints[1][0] * scale + offsetX,
            1,
            mcCable.pathPoints[1][1] * scale + offsetY)
        this.diameter = diameter

        const geometry = this.createTubeGeometry()

        const material = new THREE.MeshStandardMaterial({
            color: ColorHelper.hexToVectorColor(ColorHelper.templateColors[3]), wireframe: false
        })

        this.representation = new THREE.Mesh(geometry, material)

        // this.representation.add(new THREE.AxesHelper(1))
        // this.translateGeometry()
        this.representation.userData = { isJsonModel: true };
        group.add(this.representation.clone())
        this.scene.add(this.representation);
       
        return this;
    }


    createTubeGeometry() {

        const geomertryHelper = new GeometryHelper()

        this.direction = new THREE.Vector3()
            .subVectors(this.startPoint, this.endPoint).normalize();

        var centerPoint = new THREE.Vector3()
            .addVectors(this.startPoint, this.endPoint)
            .divideScalar(2);

        var middlePoint = new THREE.Vector3();

        if (this.fromBbox) {
            middlePoint = geomertryHelper.moveAlongVector(centerPoint, new THREE.Vector3(1, 0, 0), 1)
        }
        else {
            middlePoint = geomertryHelper.moveAlongVector(centerPoint, new THREE.Vector3(0, 1, 0), 1)
        }


        const curve = new THREE.QuadraticBezierCurve3(
            this.startPoint,
            middlePoint,
            this.endPoint
        );

        return new THREE.TubeGeometry(curve, 20, 0.01, 8, false);
    }
}