import React, { useState, useEffect, useRef } from 'react';
import { Input, Spinner, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Progress } from 'reactstrap';
import { Canvas } from 'react-three-fiber';
import Three from './ThreeJs/Three';
import Manager from './FileManager/Manager';
import DataTable from './DataTable';
import FileUpload from './FileManager/FileUpload';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useAuthContext } from '../hooks/AuthContext';
import { ThreeProvider } from './ThreeJs/Context/ThreeContext';
import ThreeActionPanel from './ThreeActionPanel';

const Home = () => {

  const baseApiUrl = process.env.REACT_APP_API_URL;
  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
  const [viewMode, setViewMode] = useState('side-by-side');
  const [viewModeRender, setViewModeRender] = useState(false);
  const [viewModeTable, setViewModeTable] = useState(false);
  const { keycloak } = useAuthContext();
  const [newName, setNewName] = useState('');
  const [actionPanel, setActionPanel] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [selectedFileIndexes, setSelectedFileIndexes] = useState([]);
  const isButtonDisabled = selectedFileIndexes.length !== 1;
  const [selectedFolderInfo, setSelectedFolderInfo] = useState(null);
  const [isActiveUploadButton, setIsActiveUploadButton] = useState(true)
  const [refreshManagerKey, setRefreshManagerKey] = useState(uuidv4());
  const [refreshTableKey, setRefreshTableKey] = useState(uuidv4());
  const [selectedFiles, setSelectedFiles] = useState([])
  const [areFilesBeingChanged, setAreFilesBeingChanged] = useState(false);
  const [selectedGuids, setSelectedGuids] = useState([]);
  const prevVelueSelectedGuids = useRef([]);
  const [threeKey, setThreeKey] = useState(uuidv4());
  const [btnList, setBtnList] = useState("custom-btn btn-white unactive");
  const [btnSideBySide, setBtnSideBySide] = useState("custom-btn btn-white active");
  const [btnFullScreen, setBtnFullScreen] = useState("custom-btn btn-white unactive");
  const [btnListIsActive, setBtnListIsActive] = useState(false);
  const [btnSideBySideIsActive, setBtnSideBySideIsActive] = useState(true);
  const [btnFullScreenIsActive, setBtnFullScreenIsActive] = useState(false);
  const [plyModelLoadProgress, setPlyModelLoadProgress] = useState(0);
  const [progressViewState, setProgressViewState] = useState("none")
  useEffect(() => {
    prevVelueSelectedGuids.current = selectedGuids;
  }, [selectedGuids])


  const handleFileDataChange = (newFileData) => {
    let data = newFileData && Array.isArray(newFileData) ? newFileData :
      (newFileData ? [newFileData] : [])
    setFileData(data);
  };

  const handeleChangeOpenState = () => {
    onRefreshKey();
    setIsOpenUploadModal(false);
  }
  const handleChangeFolderId = (info) => {
    setIsActiveUploadButton(info == null);
    setSelectedFolderInfo(info);
  };

  const uploadFile = () => {
    if (selectedFolderInfo) {
      setIsOpenUploadModal(true);
    }
  }

  const handleSelectedFile = (index, file) => {


    setSelectedFiles((prev) => {
      if (prev.includes(file.Id)) {
        return prev.filter(item => item !== file.Id);
      } else {
        return [...prev, file.Id];
      }
    })

    setSelectedFileIndexes((prevIndexes) => {
      if (prevIndexes.some(item => item.index === index && item.fileId === file.Id)) {
        return prevIndexes.filter((i) => i.fileId !== file.Id);
      } else {
        return [...prevIndexes, { index, fileId: file.Id }];
      }
    });

    setSelectedGuids((prev) => {
      if (prev.some(item => item.Id === file.Id)) {
        return prev.filter(item => item.Id !== file.Id);
      } else {
        return [...prev, file];
      }
    })

    if (!selectedFileIndexes.some(item => item.index === index && item.fileId === file.Id)) {
      setProgressViewState("block");
      setThreeKey(uuidv4());
    }
  };

  const handleCheckboxChange = (index, file) => {
    handleSelectedFile(index, file);
  };


  const handleRenameConfirm = async () => {
    await renameFileSendRequest();
    setNewName("");
    setIsRenaming(false);
  };

  const handleRenameCancel = () => {
    setIsRenaming(false);
  };

  const handleChange = (event) => {
    setNewName(event.target.value);
  };


  const deletefile = async () => {
    setFileData([]);
    setAreFilesBeingChanged(true);
    const queryString = selectedFiles.map(id => `ids=${id}`).join('&');
    const response = await axios.delete(`${baseApiUrl}/FileManager/delete?${queryString}`, {
      headers: {
        'Authorization': `Bearer ${keycloak.token}`
      }
    });
    if (response) {
      onRefreshKey();
      setAreFilesBeingChanged(false);
      setSelectedFiles([]);
    }

  }

  const renameFileSendRequest = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('name', newName);
    const config = {
      headers: {
        'Authorization': `Bearer ${keycloak.token}`
      }
    };
    const response = await axios.patchForm(`${baseApiUrl}/FileManager/rename/${selectedFiles[selectedFiles.length - 1]}`, formDataToSend, config);
    if (response) {
      onRefreshKey();
    } else {
      alert(response);
    }
  }


  const downloadFile = () => {
    let id = selectedFiles[selectedFiles.length - 1]
    if (id) {
      const fileUrl = `${baseApiUrl}/FileManager/download/${id}`;
      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', '');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

  };

  const selectedFileNodeHandler = (node, index) => {
    handleSelectedFile(index, node);
    console.log(node, index);
  }






  const buttonsPanel = () => {
    return (<>
      {areFilesBeingChanged ? <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ width: '8rem', height: '2rem' }}>
            Please wait...{' '}
            <Spinner size="sm" color="secondary" />
          </span>
        </div>
      </> : <>
        <div className='btn-panel-conteiner'>
          <div className='left-btn-conteiner'>
            <button onClick={() => uploadFile()} className="custom-btn btn-white" disabled={isActiveUploadButton}>
              <i className='ic icon-load-up'></i>{' '}
              <span>Upload</span>
            </button>
            <button onClick={() => { downloadFile() }} className="custom-btn btn-white" disabled={isButtonDisabled} >
              <i className='ic icon-load-down'></i>{' '}
              <span>Download</span>
            </button>
            <button className="custom-btn btn-white" onClick={() => setIsRenaming(true)} disabled={isButtonDisabled}>
              <i className='ic icon-edit'></i>{' '}
              <span>Rename</span>
            </button>
            <button onClick={() => deletefile()} className="custom-btn btn-red" disabled={isButtonDisabled} >
              <i className='ic icon-close'></i>{' '}
              <span>Delete</span>
            </button>
            
            
          </div>
          <div className='empty'></div>
          <div class="right-btn-conteiner">
            <div class="button-group">
              <button className={btnList} onClick={() => {
                changeActivButton('list');
                setViewMode('list');
                changeViewMode('list');
              }}>
                {btnListIsActive ? (<i className='ic icon-task-blue'></i>) : (<i className='ic icon-task'></i>)}
                <span>List</span>
              </button>
              <button className={btnSideBySide} onClick={() => {
                setViewMode('side-by-side');
                changeActivButton('side-by-side');
                changeViewMode('side-by-side');
              }}>
                {btnSideBySideIsActive ? (<i className='ic icon-edit-blue'></i>) : (<i className='ic icon-edit'></i>)}
                <span>Side-by-side</span>
              </button>
              <button className={btnFullScreen} onClick={() => {
                setViewMode('full-screen');
                changeActivButton('full-screen');
                changeViewMode('full-screen');
              }}>
                {btnFullScreenIsActive ? (<i className='ic icon-full-screan-blue'></i>) : (<i className='ic icon-full-screan'></i>)}
                <span>Full Screen</span>
              </button>
            </div>
          </div>

        </div>
      </>}

    </>);
  };

  const onRefreshKey = () => {
    setRefreshManagerKey(uuidv4())
    setRefreshTableKey(uuidv4())
    setFileData([]);
    setSelectedFiles([]);
    setSelectedFileIndexes([]);
    setSelectedGuids([]);
  }

  const changeActivButton = (mode) => {
    switch (mode) {
      case 'side-by-side':
        setBtnFullScreen("custom-btn btn-white unactive");
        setBtnSideBySide("custom-btn btn-white active")
        setBtnList("custom-btn btn-white unactive")
        setBtnFullScreenIsActive(false);
        setBtnSideBySideIsActive(true);
        setBtnListIsActive(false);
        break;
      case 'list':
        setBtnFullScreen("custom-btn btn-white unactive");
        setBtnSideBySide("custom-btn btn-white unactive")
        setBtnList("custom-btn btn-white active")
        setBtnFullScreenIsActive(false);
        setBtnSideBySideIsActive(false);
        setBtnListIsActive(true);
        break;
      case 'full-screen':
        setBtnFullScreen("custom-btn btn-white active");
        setBtnSideBySide("custom-btn btn-white unactive")
        setBtnList("custom-btn btn-white unactive")
        setBtnFullScreenIsActive(true);
        setBtnSideBySideIsActive(false);
        setBtnListIsActive(false);
        break;

      default:
        break;
    }
  }


  const changeViewMode = (mode) => {
    switch (mode) {
      case 'side-by-side':
        setViewModeTable(false);
        setViewModeRender(false);
        break;
      case 'list':
        setViewModeTable(false);
        setViewModeRender(true);
        break;
      case 'full-screen':
        setViewModeTable(true);
        setViewModeRender(false);
        break;
      default:
        break;
    }
  }


  return (
    <>
      <aside className="left-menu">
        <Manager
          key={refreshManagerKey}
          auth={keycloak}
          onFileDataChange={handleFileDataChange}
          onChangeFolderId={handleChangeFolderId}
          onRefreshKey={onRefreshKey}
          onSelectedFileNode={selectedFileNodeHandler}
          selectedIndexes={selectedFileIndexes}

        />
      </aside>
      <section className="right-container">
        <div className="content-viewer">
          {buttonsPanel()}
          <Row>
            <Col>
              {(isOpenUploadModal && (
                <FileUpload onChangeOpenState={handeleChangeOpenState} info={selectedFolderInfo} authToken={keycloak.token} />
              ))}
              <Row>
                <Col hidden={viewModeTable} style={{ alignItems: "center", width: '50%', padding: '24px', overflow: "hidden" }}>
                  <DataTable
                    key={refreshTableKey}
                    fileData={fileData}
                    onhandleCheckboxChange={handleCheckboxChange}
                    selectedIndexes={selectedFileIndexes} />
                </Col>

                <Col hidden={viewModeRender} style={{ width: '50%', zIndex:1, padding: '1px', borderLeft: viewModeTable ? "none" : "solid 1px #E4E4E4" }}>
                  <Canvas style={{ width: "100%", height: '88vh' }} pixelRatio={window.devicePixelRatio}>
                    <ThreeProvider>
                      <Three
                        key={threeKey}
                        auth={keycloak}
                        guids={selectedGuids}
                        isDefault={isButtonDisabled}
                        setLoadProgress={setPlyModelLoadProgress}
                        setProgressViewState={setProgressViewState}
                      />
                    </ThreeProvider>
                  </Canvas>

                  <ThreeActionPanel panelName={"three"}/>
                  <div className='progres-load-bottom-right' style={{ display: progressViewState }}>
                    <span>Loading</span>
                    <Progress value={plyModelLoadProgress} max="100" />
                  </div>
                </Col>
              </Row>
            </Col>
            <Modal isOpen={isRenaming} toggle={handleRenameCancel} backdropClassName={"custom-modal-backdrop"}>
              <ModalHeader className={"custom-modal-text"} color='#2E303F' toggle={handleRenameCancel}>Rename File</ModalHeader>
              <ModalBody>
                <Input type="text" value={newName} onChange={handleChange} />
              </ModalBody>
              <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button className='custom-btn btn-white' onClick={handleRenameCancel}>
                  <i className='ic icon-close-default'></i>{' '}
                  <span>Cancel</span>
                </button>
                <button className='custom-btn btn-blue' onClick={handleRenameConfirm}>
                  <i className='ic icon-chekced'></i>{' '}
                  <span>Confirm</span>
                </button>
              </ModalFooter>
            </Modal>
          </Row>

        </div>
      </section>
    </>
  );
}

export default Home;
