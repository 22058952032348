import * as THREE from 'three';

export default class ColorHelper {
    static #leColors = `[
      {"hex": "B71C1C"}, {"hex": "D32F2F"}, {"hex": "F44336"}, {"hex": "E57373"}, {"hex": "FFCDD2"},
      {"hex": "880E4F"}, {"hex": "C2185B"}, {"hex": "E91E63"}, {"hex": "F06292"}, {"hex": "F8BBD0"},
      {"hex": "4A148C"}, {"hex": "7B1FA2"}, {"hex": "9C27B0"}, {"hex": "BA68C8"}, {"hex": "E1BEE7"},
      {"hex": "311B92"}, {"hex": "512DA8"}, {"hex": "673AB7"}, {"hex": "9575CD"}, {"hex": "D1C4E9"},
      {"hex": "1A237E"}, {"hex": "303F9F"}, {"hex": "3F51B5"}, {"hex": "7986CB"}, {"hex": "C5CAE9"},
      {"hex": "0D47A1"}, {"hex": "1976D2"}, {"hex": "2196F3"}, {"hex": "64B5F6"}, {"hex": "BBDEFB"},
      {"hex": "01579B"}, {"hex": "0288D1"}, {"hex": "03A9F4"}, {"hex": "4FC3F7"}, {"hex": "B3E5FC"},
      {"hex": "006064"}, {"hex": "0097A7"}, {"hex": "00BCD4"}, {"hex": "4DD0E1"}, {"hex": "B2EBF2"},
      {"hex": "004D40"}, {"hex": "00796B"}, {"hex": "009688"}, {"hex": "4DB6AC"}, {"hex": "B2DFDB"},
      {"hex": "194D33"}, {"hex": "388E3C"}, {"hex": "4CAF50"}, {"hex": "81C784"}, {"hex": "C8E6C9"},
      {"hex": "33691E"}, {"hex": "689F38"}, {"hex": "8BC34A"}, {"hex": "AED581"}, {"hex": "DCEDC8"},
      {"hex": "827717"}, {"hex": "AFB42B"}, {"hex": "CDDC39"}, {"hex": "DCE775"}, {"hex": "F0F4C3"},
      {"hex": "F57F17"}, {"hex": "FBC02D"}, {"hex": "FFEB3B"}, {"hex": "FFF176"}, {"hex": "FFF9C4"},
      {"hex": "FF6F00"}, {"hex": "FFA000"}, {"hex": "FFC107"}, {"hex": "FFD54F"}, {"hex": "FFECB3"},
      {"hex": "E65100"}, {"hex": "F57C00"}, {"hex": "FF9800"}, {"hex": "FFB74D"}, {"hex": "FFE0B2"},
      {"hex": "BF360C"}, {"hex": "E64A19"}, {"hex": "FF5722"}, {"hex": "FF8A65"}, {"hex": "FFCCBC"},
      {"hex": "3E2723"}, {"hex": "5D4037"}, {"hex": "795548"}, {"hex": "A1887F"}, {"hex": "D7CCC8"},
      {"hex": "263238"}, {"hex": "455A64"}, {"hex": "607D8B"}, {"hex": "90A4AE"}, {"hex": "CFD8DC"},
      {"hex": "000000"}, {"hex": "525252"}, {"hex": "969696"}, {"hex": "D9D9D9"}, {"hex": "FFFFFF"}
  ]`;
    static #_colorRatio = []
    static get colors() {
      return JSON.parse(this.#leColors);
    }
    static get templateColors() {
      return [
        "01579B",
        "B71C1C",
        "01796F",
        "FFEB3B",
        "4A148C",
        "F57F17",
        "795548",
        "969696",
        "E91E63",
        "FFEB3B"
      ];
    }
    static get colorRatio() {
  
      let mapColors = this.#_colorRatio.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.value }), {});
      return mapColors;
    }
    static get randomColors() {
      let randomNumber = this.#getRandomNumberInRange();
      let _colors = Array.from({ length: randomNumber - 5 }, (_, index) => index + randomNumber - 5);
      return _colors.map(index => this.colors[index]);
  
    }
    static #getRandomNumberInRange() {
      const randomInterval = Math.floor(Math.random() * 11);
      const result = randomInterval * 6 + 5;
      return result;
    }
    static componentToHex(c) {
      let hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    };
  
    static rgbToHex(r, g, b) {
      return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
    }
  
    static hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    }

    static hexToVectorColor(hexColor) {
      const color= this.hexToRgb(hexColor);
      return new THREE.Color(color.r / 255, color.g / 255, color.b / 255);
    }
    
  }