import React from 'react';
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader';
import axios from 'axios';
import { compress,decompress } from '../Helpers/CompressionHelper';

async function fetchWithAuth(url, guid, token, setLoadProgress, setProgressViewState, cache) {
    const { addToCache, getFromCache } = cache;
    let data = getFromCache(guid);
    if (data) {
      console.log("get from cache");
      setProgressViewState("none");
      return data;
    } else {
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        onDownloadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setLoadProgress(progress);
          if (progress > 98) {
            setProgressViewState("none");
          }
        }
      };
      try {
        console.log("start load data");
        const response = await axios.get(url, config);
        console.log("end load data");
  
        if (!response) {
          alert(response);
          throw new Error('Failed to fetch resource');
        }
        let decompressed = decompress(response.data);
        addToCache(guid, decompressed);
        return decompressed;
      } catch (error) {
        console.error('Failed to fetch resource:', error);
        throw error;
      }
    }
  }
  
  
function useAuthLoader({isPly,url, guid, token, setLoadProgress, setProgressViewState, cache}) {
    const [data, setData] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(null);
  
    React.useEffect(() => {
      fetchWithAuth(url, guid, token, setLoadProgress, setProgressViewState, cache)
        .then(response => {
          if (isPly) {
            const loader = new PLYLoader();
            setData(loader.parse(response));
          }
          else{
            setData(JSON.parse(response));
          }
          setLoading(false);
        })
        .catch(error => {
          setError(error);
          setLoading(false);
        });
    }, [url, token]);
    return { data, loading, error };
  
  }

  export default useAuthLoader;