import React, { useEffect, useState } from 'react';
import NavMenu from './NavMenu';
import Home from './Home';
import AuthProvider, { useAuthContext } from '../hooks/AuthContext';
import { ThreeActionsProvider } from './ThreeJs/Context/ThreeActionsContext';
const Layout = () => {
  return (
    <AuthProvider>
      <NavMenu />
      <ThreeActionsProvider>
        <Home />
      </ThreeActionsProvider>
    </AuthProvider>
  );
}
export default Layout;