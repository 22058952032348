import React, { createContext, useContext, useState } from 'react';
import * as THREE from 'three';

const ThreeContext = createContext(null);

export const useThreeContext = () => useContext(ThreeContext);

export const ThreeProvider = ({ children }) => {
  const [pick,setPick]= useState(null);
  const [group,setGroup]= useState(new THREE.Group());
  const setPickPane = (p) => {
    console.log(" work setPick");
   setPick(p)
  };
  const setModelGroup = (g) => {
    console.log(" work group ");
   setGroup(g);
  };
  return (
    <ThreeContext.Provider value={{ pick, setPickPane,group,setModelGroup}}>
      {children}
    </ThreeContext.Provider>
  );
};