import * as THREE from 'three';
import ColorHelper from '../../../Helpers/ColorHelper';

export default class Elbow {
    constructor(scene) {
        this.scene = scene;
        this.startPoint = null;
        this.middlePoint = null;
        this.endPoint = null;
        this.representation = null;
    }

    create(
        point1 = new THREE.Vector3(0, 0, 0),
        point2 = new THREE.Vector3(1, 0, 0),
        point3 = new THREE.Vector3(2, 0, 0),
        diameter,group) {

        this.startPoint = point1
        this.middlePoint = point2
        this.endPoint = point3

        const circleShape = this.createShape(diameter, 12)

        const curve = new THREE.QuadraticBezierCurve3(
            this.startPoint, this.middlePoint, this.endPoint);

        const extrudeSettings = {
            steps: 32,
            depth: 16,
            bevelEnabled: true,
            bevelThickness: 1,
            bevelSize: 1,
            bevelOffset: 0,
            bevelSegments: 1,
            extrudePath: curve
        };

        const geometry = new THREE.ExtrudeGeometry(circleShape, extrudeSettings);
        const material = new THREE.MeshStandardMaterial({ color: ColorHelper.hexToVectorColor(ColorHelper.templateColors[8]), wireframe: false });
        const mesh = new THREE.Mesh(geometry, material);
        mesh.userData={isJsonModel:true};
        group.add(mesh.clone());
        this.scene.add(mesh);
        
        return this;
    }

    createShape(diameter = 0.1, segments = 32) {
        var radius = diameter / 2;

        var circleShape = new THREE.Shape();
        var theta_next, x_next, y_next, j;
        for (var i = 0; i < segments; i++) {
            var theta = ((i + 1) / segments) * Math.PI * 2.0;

            var x = radius * Math.cos(theta);
            var y = radius * Math.sin(theta);
            var j = i + 2;

            if ((j - 1) === segments) j = 1;

            theta_next = (j / segments) * Math.PI * 2.0;

            x_next = radius * Math.cos(theta_next);
            y_next = radius * Math.sin(theta_next);

            circleShape.moveTo(0, 0);
            circleShape.lineTo(x, y);
            circleShape.lineTo(x_next, y_next);
            circleShape.lineTo(0, 0);
        }

        return circleShape
    }
}
