import React, { useRef, useState, Suspense, useEffect, useMemo } from 'react';
import { useThree, useFrame } from 'react-three-fiber';
import { useControls, buttonGroup, button, folder } from 'leva'
import PLYModel from './PLYModel';
import CreateModelFromJson from './Elements/CreateModelFromJson';
import * as THREE from 'three';
import { GizmoHelper, GizmoViewport, OrbitControls } from '@react-three/drei'
import CrossSectional from './CrossSectional';
import { useThreeActions } from './Context/ThreeActionsContext';
import { useThreeContext } from './Context/ThreeContext';

const Controls = ({ orbitPoint, isAsignetOrbitPoint, setIsAsignetOrbitPoint, orbitControlsRef }) => {

  const { camera, gl } = useThree();
  useEffect(() => {
    if (!isAsignetOrbitPoint) {
      if (orbitPoint) {
        orbitControlsRef.current.target.set(orbitPoint.x, orbitPoint.y, orbitPoint.z);
        setIsAsignetOrbitPoint(true);
      }
    }


  }, [orbitPoint])
  useFrame(() => orbitControlsRef.current.update());

  return <OrbitControls makeDefault ref={orbitControlsRef} args={[camera, gl.domElement]} enableDamping dampingFactor={0.25} rotateSpeed={0.5} />;
};

const SceneInfo = () => {
  const { scene } = useThree();

  console.log(scene.children); // Вывод всех объектов в сцене

  return null;
};

const Three = ({ auth, guids, isDefault = true, setLoadProgress, setProgressViewState, showFocusCamera }) => {
  const [show, setShow] = useState(false);
  const [orbitPoint, setOrbitPoint] = useState(null);
  const [isAsignetOrbitPoint, setIsAsignetOrbitPoint] = useState(false);
  const { camera, gl, scene } = useThree();
  const groups = useRef();
  const plyModelGroupRef = useRef();
  const orbitControlsRef = useRef();
  const { addAction } = useThreeActions();
  const { group } = useThreeContext();
  const [jsonModels, setJsonModels] = useState([]);
  useEffect(() => {
    addAction("focus", () => focusCamera());
    addAction("Cross-sectional", () => {
      setShow(prev => prev = !prev);
      console.log("ok cross-sectional");
    })

    console.log("ok add actions");
  }, [])






  const focusCamera = () => {
    let groupsFiltered = groups.current.filter(x => x != undefined) ?? [];

    if (groupsFiltered.length > 0) {
      const tempGroup = new THREE.Group();
      const isManyGroups = groupsFiltered.length < 2;
      let boundingCenter = undefined;
      groupsFiltered.forEach(x => {
        if (isManyGroups && x.children.length < 2) {
          x.children.forEach(o => boundingCenter = o.geometry.boundingSphere.center);
        } else {
          x.children.forEach(o => tempGroup.add(o));
        }
      });
      const box = new THREE.Box3().setFromObject(tempGroup);
      const center = boundingCenter != undefined ? boundingCenter : box.getCenter(new THREE.Vector3());
      const size = center;
      const maxDim = Math.max(size.x, size.y, size.z);
      const fov = 75;
      const distance = (maxDim / Math.tan(THREE.MathUtils.degToRad(fov))) / 2;
      const newPos = center.clone().add(new THREE.Vector3(250, center.y, distance));
      camera.position.copy(newPos);
      camera.lookAt(center);
      camera.rotateOnAxis(new THREE.Vector3(0, 1, 0), Math.PI / 2)
      camera.updateProjectionMatrix();
      camera.far = 2000;
    }

  };


  useEffect(() => {
    groups.current = [plyModelGroupRef.current, undefined];
  }, [plyModelGroupRef.current, group]);

  const toggleOrbitControls = (e) => {
    const orbitControls = orbitControlsRef.current;
    if (orbitControls) {
      orbitControls.enabled = e;
    }
  };

  // const createJsonModel = (file, index) => {
  //   if (file.ModelType == "json") {
  //     try {
  //       //TODO: fix 
  //       scene.remove(group);
  //       group.clear();
  //     } catch (error) {

  //     }
  //   }

  //   return ()
  // }
  return (

    <>
      <GizmoHelper alignment="top-right" margin={[50, 50]}>
        <GizmoViewport labelColor="white" axisHeadScale={0.8} />
      </GizmoHelper>
      <hemisphereLight args={["#fff", "#333", 1]} />

      <Controls
        orbitControlsRef={orbitControlsRef}
        orbitPoint={orbitPoint}
        isAsignetOrbitPoint={isAsignetOrbitPoint}
        setIsAsignetOrbitPoint={setIsAsignetOrbitPoint}
      />

      <CrossSectional
        isShow={show}
        groups={[plyModelGroupRef.current, undefined]}
        toggleOrbitControls={toggleOrbitControls} />

      <gridHelper args={[100, 100]} />
      {guids.map((file, index) => (
        <Suspense key={index} fallback={null}>
          {file.ModelType == "ply" ? (<group ref={plyModelGroupRef} name='ply model'>
            <PLYModel
              auth={auth}
              guid={file.Id}
              setOrbitPoint={setOrbitPoint}
              setLoadProgress={setLoadProgress}
              setProgressViewState={setProgressViewState}
            />
          </group>) : (<CreateModelFromJson
            key={index}
            auth={auth}
            guid={file.Id}
            setOrbitPoint={setOrbitPoint}
            setLoadProgress={setLoadProgress}
            setProgressViewState={setProgressViewState}
            group={group} />)}
        </Suspense>
      ))}
    </>

  );
};

export default Three;