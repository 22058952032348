import * as THREE from 'three';
import GeometryHelper from './GeometryHelper';
import Elbow from './Elbow';
import ColorHelper from '../../../Helpers/ColorHelper';

export default class Conduit {
    constructor(scene = new THREE.Scene()) {
        this.scene = scene
        this.startPoint = null
        this.endPoint = null
        this.direction = null
        this.representation = null
    }

    create(
        vector1 = new THREE.Vector3(0, 0, 0),
        vector2 = new THREE.Vector3(1, 0, 0),
        diameter,group) {

        this.startPoint = vector1
        this.endPoint = vector2
        this.diameter = diameter

        const cylinder = this.createCylinderGeometry(diameter)

        const material = new THREE.MeshStandardMaterial({
            color: ColorHelper.hexToVectorColor(ColorHelper.templateColors[4]), wireframe: false
        })

        this.representation = new THREE.Mesh(cylinder, material)
        this.representation.userData = {isJsonModel:true};
        this.translateGeometry()

        // this.representation.add(new THREE.AxesHelper(1))
        group.add(this.representation.clone());
        this.scene.add(this.representation);
       
        return this;
    }

    createCylinderGeometry(diameter) {

        this.direction = new THREE.Vector3()
            .subVectors(this.startPoint, this.endPoint).normalize();

        const distance = this.startPoint.distanceTo(this.endPoint)

        return new THREE.CylinderGeometry(diameter / 2, diameter / 2, distance)
    }

    translateGeometry() {
        const center = new THREE.Vector3().lerpVectors(this.startPoint, this.endPoint, 0.5)
        this.representation.position.set(center.x, center.y, center.z)

        this.representation.lookAt(this.endPoint)
        this.representation.rotateX(Math.PI / 2)
    }

    connectTo(
        conduit = new Conduit(this.scene),group) {

        const geomertryHelper = new GeometryHelper()

        var p1 = geomertryHelper.moveAlongVector(
            this.endPoint, this.direction, 0.3)

        var p2 = geomertryHelper.moveAlongVector(
            conduit.startPoint, conduit.direction, -0.3)

        const elbow = new Elbow(this.scene).create(p1, this.endPoint, p2, this.diameter,group)

        this.scene.remove(this.representation)
        this.scene.remove(conduit.representation)
       
        this.create(this.startPoint, p1, this.diameter,group)
        conduit.create(p2, conduit.endPoint, conduit.diameter,group)

        return elbow
    }
}
