import * as THREE from 'three';
import Conduit from "./Conduit.js";
import McCable from "./McCable.js";
import Receptacle from "./Receptacle.js";
import HomerunBox from "./HomerunBox.js";
import Panel from "./Panel.js";

export default class JsonModel {
    constructor(scena,group) {
        this.scale = 0.377953 / 14;
        this.offsetX = -100;
        this.offsetZ = -100;
        this.scene = scena??new THREE.Scene();
        this.conduitsCache = [];
        this.group=group;
    }

    createPanels() {
        const grouped = this.conduitsCache.reduce((result, item) => {
            (result[item.data.panelName] = result[item.data.panelName] || []).push(item);
            return result;
        }, {});

        for (const group in grouped) {
            let runs = grouped[group];

            new Panel(this.scene).create(runs, this.scale, this.offsetX, this.offsetZ,this.group)
        }

    }


    createHomerunBoxes(item) {
        if (item.elementType != "MC_CABLE") return;

        new HomerunBox(this.scene).create(item, this.scale, this.offsetX, this.offsetZ,this.group)
    }

    createReceptacle(item) {
        if (item.elementType != "MC_CABLE") return;

        new Receptacle(this.scene).create(item, this.scale, this.offsetX, this.offsetZ,this.group)
    }

    createMcCables(item) {
        if (item.elementType != "MC_CABLE") return;

        new McCable(this.scene).create(item, 0.04, this.scale, this.offsetX, this.offsetZ,this.group)
    }

    createConduits(item) {
        if (item.elementType != "CONDUIT") return;
        this.conduitsCache.push(item);
        let path = item.pathPoints;
        let points = [];

        for (let i = 0; i < path.length; i++) {
            let point = path[i]

            points.push(new THREE.Vector3(
                point[0] * this.scale + this.offsetX,
                10,
                point[1] * this.scale + this.offsetZ))
        }

        let startPoint = new THREE.Vector3(points[0].x, 6, points[0].z)
        let endPoint = new THREE.Vector3(points.at(-1).x, 8, points.at(-1).z)

        points.unshift(startPoint)
        points.push(endPoint)


        let conduits = [];

        for (let i = 0; i < points.length - 1; i++) {
            let p1 = points[i]
            let p2 = points[i + 1]

            conduits.push(new Conduit(this.scene).create(p1, p2, this.scale * 2,this.group))
        }

        for (let i = 0; i < conduits.length - 1; i++) {
            let c1 = conduits[i]
            let c2 = conduits[i + 1]
            c1.connectTo(c2,this.group);
        }
    }

    create(data) {
       
        data.forEach(item => this.createConduits(item));
        data.forEach(item => this.createMcCables(item));
        data.forEach(item => this.createReceptacle(item));
        data.forEach(item => this.createHomerunBoxes(item));
        this.createPanels();
        console.log(this.group);
    }
}