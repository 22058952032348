import * as THREE from 'three';
import GeometryHelper from './GeometryHelper';
import ColorHelper from '../../../Helpers/ColorHelper';

export default class Receptacle {
    constructor(scene = new THREE.Scene()) {
        this.scene = scene
    }

    create(item, scale = 1, offsetX = 0, offsetY = 0,group){
        const geometry = new THREE.BoxGeometry( 0.4, 0.4, 0.2 ); 
        const material = new THREE.MeshStandardMaterial( {color: ColorHelper.hexToVectorColor(ColorHelper.templateColors[2])} ); 
        const cube = new THREE.Mesh( geometry, material ); 

        cube.position.x = item.pathPoints[1][0] * scale + offsetX
        cube.position.y = 1
        cube.position.z = item.pathPoints[1][1] * scale + offsetY
        cube.userData={isJsonModel:true};
        group.add(cube.clone());
        this.scene.add( cube );
       
    }
}