import React, { useState } from 'react';
import "./DataTable.css";

const dateCovert = (dateAsString) => {
    let parts = dateAsString.match(/\d+/g);
    let year = parseInt(parts[0]);
    let month = parseInt(parts[1]) - 1;
    let day = parseInt(parts[2]);
    let hour = parseInt(parts[3]);
    let minute = parseInt(parts[4]);
    let second = parseInt(parts[5]);
    let date = new Date(year, month, day, hour, minute, second);
    return date.toDateString();
}


const DataTable = ({ fileData, onhandleCheckboxChange, selectedIndexes }) => {

    return (<>
        <table className='data-table'>
            <thead>
                <tr>
                    <th>{' '}</th>
                    <th>Name</th>
                    {/* <th>Ver</th> */}
                    <th>Last Modified</th>
                    <th>Size</th>
                    <th>Author</th>
                    <th>Comments</th>
                </tr>
            </thead>
            <tbody>
                {fileData.map((file, index) => (
                    <tr key={file.Id}>
                        <td>
                            <input type="checkbox"
                                onChange={() => onhandleCheckboxChange(index, file)}
                                checked={selectedIndexes.some(item => item.index === index && item.fileId === file.Id)} />
                        </td>
                        <td>
                            {file.Name}
                        </td>
                        {/* <td>{file.Version}</td> */}
                        <td>{dateCovert(file.Modified)}</td>
                        <td>{file.Size}</td>
                        <td>{file.Author}</td>
                        <td>{file.Comments}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    </>);
}

export default DataTable;