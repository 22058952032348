import pako from 'pako';

function compress(inputData) {
    if (!inputData) {
        throw new Error("can`t null or empty data");
    }

    return pako.deflate(inputData);
}

function decompress(inputData) {
    if (!inputData) {
        throw new Error("can`t null or empty data");
    }
    const binaryString = atob(inputData);
    const byteArray = new Uint8Array(binaryString.length);

    for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
    }
    return pako.inflateRaw(new Uint16Array(byteArray), {
        to: 'string'
    });
}

export { compress, decompress }