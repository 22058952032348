import React from 'react';
import { useThreeActions } from './ThreeJs/Context/ThreeActionsContext';


const ThreeActionPanel = ({ panelName }) => {
    const { actions } = useThreeActions();

    const crossSectional = actions['Cross-sectional'];
    const focus = actions['focus'];

    return (

        <div className="panels-container">
            <div className="button-panel">
                <button onClick={() =>{
                     crossSectional();
                     console.log("Ok click cross");
                }} className="panel-button">
                    <span>Area X</span>
                </button>
                <button onClick={() =>{ 
                    focus();
                    console.log("Ok click focus");
                }
                    } className="panel-button">
                    <span>focus</span>
                </button>
            </div>
        </div>

    );
};

export default ThreeActionPanel;
