import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../hooks/AuthContext';
import './NavMenu.css';
import { Color } from 'three';


export default function NavMenu() {
  const [userName, setUserName] = useState(" ");
  const { keycloak } = useAuthContext();

  useEffect(() => {
    if (keycloak) {
      let user = keycloak.tokenParsed.given_name;
      if (user) {
        setUserName(user);
      }
    }
  }, [keycloak])

  const logout = async event=>{

    await keycloak.logout();
  }


  return (
    <>
      <header>
        <nav className="top-navbar">
          <div className="navbar">
            <div className="logo">
              <img width="32" height="32" src="./images/3d-rotate.svg" alt="logo" />
              <span>Drawer.<span style={{color:"#2377FC"}}>Live</span></span>
            </div>
            <div className="links">
              <a target="_" href="https://app.drawer.live/swagger/index.html">Swagger</a>
            </div>
            <div className="user-info">
              <img src="./images/default_awatar.png" alt="avatar" />
              <span className="username">{userName}</span>
              <div className="dropdown">
                <i className="dropbtn"><img src="./images/arrow-down.svg" alt="Logout" /></i>
                <div className="dropdown-content">
                  <a  onClick={logout} href="#">Logout</a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>


    </>
  );
}
