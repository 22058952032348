import React, { useContext, useState } from "react";

const CacheContext = React.createContext();

export const useCacheContext = () => {
    return useContext(CacheContext);
}

const CacheProvider = ({ children }) => {
    const [cache, setCache] = useState({});
    const addToCache = (key, value) => {
        setCache(prevCache => ({
            ...prevCache,
            [key]: value
        }));
    };
    const getFromCache = (key) => {
        return cache[key];
    };

    return (
        <CacheContext.Provider value={{ addToCache, getFromCache }}>
            {children}
        </CacheContext.Provider>
    );
};

export default CacheProvider;
