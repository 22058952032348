import * as THREE from 'three';

class PlaneHelper extends THREE.Line {


    constructor(plane, size = 1, hex = 0xffff00) {


        const color = hex;
        const positions = [
            1, 1, 0,
            -1, 1, 0,
            -1, -1, 0,
            1, -1, 0,
			1, 1, 0,
        ];
        const geometry = new THREE.BufferGeometry();
        geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
        geometry.computeBoundingSphere();

        super(geometry, new THREE.LineBasicMaterial({ color: color, toneMapped: false }));
        this.type = 'PlaneHelper';
        this.plane = plane;
        this.size = size;
        const positions2 = [1, 1, 0, - 1, 1, 0, - 1, - 1, 0, 1, 1, 0, - 1, - 1, 0, 1, - 1, 0];
        const geometry2 = new THREE.BufferGeometry();
        geometry2.setAttribute('position', new THREE.Float32BufferAttribute(positions2, 3));
        geometry2.computeBoundingSphere();


        //this.add(new  THREE.Mesh( geometry2, new THREE.MeshBasicMaterial( { color: color, opacity: 0.2, transparent: true, depthWrite: false, toneMapped: false } ) ));

    }


    updateMatrixWorld(force) {

        this.position.set(0, 0, 0);
        this.scale.set(1 * this.size, 0.3 * this.size, 1);
        this.lookAt(this.plane.normal);
        this.translateZ(- this.plane.constant);
        super.updateMatrixWorld(force);

    }

    dispose() {

        this.geometry.dispose();
        this.material.dispose();
        this.children[0].geometry.dispose();
        this.children[0].material.dispose();

    }

}

export default PlaneHelper;