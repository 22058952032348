class DataHelper {
    static ParseXml(xml = "") {
        if (xml == "") {
            return null;
        }
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(xml, "text/xml");
        function xmlNodeToObject(node) {
            let obj = {};
            if (node.nodeType === Node.ELEMENT_NODE) {
                if (node.hasChildNodes()) {
                    for (let i = 0; i < node.childNodes.length; i++) {
                        let child = node.childNodes[i];
                        if (child.nodeType === Node.ELEMENT_NODE) {
                            if (child.hasChildNodes() && child.childNodes.length === 1 && child.firstChild.nodeType === Node.TEXT_NODE) {
                                if (obj[child.nodeName]) {
                                    if (!Array.isArray(obj[child.nodeName])) {
                                        obj[child.nodeName] = [obj[child.nodeName]];
                                    }
                                    obj[child.nodeName].push(child.textContent.trim());
                                } else {
                                    obj[child.nodeName] = child.textContent.trim();
                                }
                            } else {
                                if (!obj[child.nodeName]) {
                                    obj[child.nodeName] = xmlNodeToObject(child);
                                } else {
                                    if (!Array.isArray(obj[child.nodeName])) {
                                        obj[child.nodeName] = [obj[child.nodeName]];
                                    }
                                    obj[child.nodeName].push(xmlNodeToObject(child));
                                }
                            }
                        }
                    }
                }
                if (node.attributes.length > 0) {
                    obj["@attributes"] = {};
                    for (let j = 0; j < node.attributes.length; j++) {
                        let attribute = node.attributes[j];
                        obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
                    }
                }
            }
            return obj;
        }

        let result = xmlNodeToObject(xmlDoc.documentElement);
        let mainFolders = result.Folders.FolderViewModel;
        function filterFolders(folders, mainFolders) {
            let filteredFolders = [];
            folders.forEach(function (folder) {
                let parentFolderId = folder.ParentFolderId;
                let isParentInMainTree = mainFolders.some(function (mainFolder) {
                    return mainFolder.Id === parentFolderId;
                });
                if (!isParentInMainTree) {
                    filteredFolders.push(folder);
                }
            });
            return filteredFolders;
        }
        
        return filterFolders(result.Folders.FolderViewModel, mainFolders);

    }
}

export default DataHelper;