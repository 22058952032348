import * as THREE from 'three';
import GeometryHelper from './GeometryHelper';
import ColorHelper from '../../../Helpers/ColorHelper';

export default class HomerunBox {
    constructor(scene = new THREE.Scene()) {
        this.scene = scene;
        
    }

    create(item, scale = 1, offsetX = 0, offsetY = 0,group){
        var fromBbox = item.data.from_hrbox == "True"

        if(fromBbox){
            const geometry = new THREE.BoxGeometry( 0.4, 0.8, 0.4 ); 
            const material = new THREE.MeshStandardMaterial( {color: ColorHelper.hexToVectorColor(ColorHelper.templateColors[5])}); 
            const cube = new THREE.Mesh( geometry, material ); 
    
            cube.position.x = item.pathPoints[0][0] * scale + offsetX
            cube.position.y = 8
            cube.position.z = item.pathPoints[0][1] * scale + offsetY
            cube.userData={isJsonModel:true};
            group.add(cube.clone());
            this.scene.add( cube );
           
        }
    }
}