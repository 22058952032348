import React, { useState, useEffect } from 'react';
import Keycloak from 'keycloak-js';

const useAuth = () => {

    const [keycloak, setKeycloak] = useState(null);
    const [isLogin, setLogin] = useState(false);
    useEffect(() => {
        const kc = new Keycloak({
            url: "https://keycloak.consedge.app",
            realm: "Drawer",
            clientId: "e99065d6"
        });

        const initOption={
            onLoad: 'login-required',
            checkLoginIframe: false,
            pkceMethod: "S256"
        };

        kc.init(initOption)
        .then((authenticated) => {
                setKeycloak(kc);
                setLogin(authenticated);
            })
            .catch((error) => {
                console.error("Authentication error:", error);
            });
    }, []);

    useEffect(() => {
        if (keycloak) {
            handleTokenRefresh();
        }
    }, [keycloak]);

    const handleTokenRefresh = () => {

        keycloak.updateToken(70) //seconds
            .then(refreshed => {
                console.log("Token refreshed request", refreshed);
                if (refreshed) {
                    // if (onTokenRefresh)
                    //     onTokenRefresh();
                    console.log('Token refreshed successfully');
                } else {
                    console.warn('Token not refreshed, or the token already expired');
                }
            })
            .catch(error => console.error('Token refresh failed:', error));
    }

    return { isLogin: isLogin, kc: keycloak };
}


export default useAuth;
