import * as THREE from 'three';
import GeometryHelper from './GeometryHelper';
import ColorHelper from '../../../Helpers/ColorHelper';

export default class Panel {
    constructor(scene = new THREE.Scene()) {
        this.scene = scene
    }

    create(runs, scale = 1, offsetX = 0, offsetY = 0,group){
        var points = new Array();

        runs.forEach(element => {
            var point = new THREE.Vector3( 
                element.pathPoints[0][0] * scale + offsetX,
                0,
                element.pathPoints[0][1] * scale + offsetY,
                );
            points.push(point);
        });

        var sum = new THREE.Vector3();
        for (var i = 0; i < points.length; i++) {
            sum.add(points[i]);
        }

        var direction = new THREE.Vector3()
            .subVectors(points[0], points.at(-1)).normalize();

        var angle = new THREE.Vector3(1, 0, 0).angleTo(direction);

        var center = sum.divideScalar(points.length);

        var height = 6;
        var width = runs.length * 0.1
        if(width < 1.2){ width = 1.2 }

        const geometry = new THREE.BoxGeometry( width, height, 0.5 ); 
        const material = new THREE.MeshStandardMaterial( {color: ColorHelper.hexToVectorColor(ColorHelper.templateColors[1])} ); 
        const cube = new THREE.Mesh( geometry, material ); 

        cube.position.x = center.x;
        cube.position.y = center.y + height / 2;
        cube.position.z = center.z;
        cube.rotateY(angle)
        cube.userData={isJsonModel:true};
        group.add(cube.clone());
        this.scene.add(cube);
        
    }
}